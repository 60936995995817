import React, { useEffect, useState } from "react";
import { useLastPageFilters } from "../stores/SystemStore";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import CommonSpinner from "../components/CommonSpinner";
import ImportExportDefinitionsDialiog from "./ImportExportDefinitionsDialiog";
import SysModels from "../models";
import ConfirmDialog from "../components/ConfirmDialog";
import toastStore from "../stores/ToastStore";

function ImportExportDefinitions(props: {
  entityTemplateId: string;
  fields: SysModels.EntityTemplateFieldOutputDto[];
}) {
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const list = useFetchHelper(
    async () =>
      SysServices.http.importExportDefinition.list(
        paging.page,
        paging.pageSize,
        {
          entityTemplateId: props.entityTemplateId,
        }
      ),
    "Definitions"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
      }
    },
    "EntityTemplate-ImportExportDefinitions"
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: "",
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, pageFilters.ready]);

  const [showAdd, setShowAdd] = useState<{ id?: string }>();
  const [showDel, setShowDel] = useState<SysModels.ImportExportGridItemDto>();

  return (
    <div>
      {showAdd && (
        <ImportExportDefinitionsDialiog
          id={showAdd.id}
          entityTemplateId={props.entityTemplateId}
          fields={props.fields}
          onClose={() => {
            setShowAdd(undefined);
          }}
          done={(data) => {
            if (data) {
              list.getData();
            }
            setShowAdd(undefined);
          }}
        />
      )}
      {showDel && (
        <ConfirmDialog
          title="Confirm Delete"
          message={`Do you really want to delete this definition (${showDel.name})?`}
          buttons="yesno"
          show={true}
          done={(rtn) => {
            if (rtn === "yes") {
              SysServices.http.importExportDefinition
                .delete(showDel.id || "", props.entityTemplateId)
                .then((data) => {
                  toastStore.showToast("Definition Deleted", "success");
                  list.getData();
                })
                .catch((err) => {
                  toastStore.showError("Failed Deleting Definition", err);
                });
            }
            setShowDel(undefined);
          }}
        />
      )}
      {list.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {list.status !== FetchStatus.InProgress && (
        <>
          <div className="mb-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={(e) => {
                setShowAdd({});
              }}
            >
              Add Definition
            </button>
          </div>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th className="w-30">Name</th>
                <th className="w-50">Description</th>
                <th className="w-20">Type</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {!list.data?.importExportGridItemDtos?.length && (
                <tr>
                  <td colSpan={4}>No Record(s) Found</td>
                </tr>
              )}
              {list.data?.importExportGridItemDtos?.map((row) => (
                <tr
                  key={row.id}
                  className="pointer"
                  onClick={(e) => {
                    setShowAdd({ id: row.id });
                  }}
                >
                  <td>{row.name}</td>
                  <td>{row.description}</td>
                  <td>{row.isImportDefinition ? "Import" : "Export"}</td>
                  <th className="text-center">
                    <i
                      className="fa fa-trash text-danger pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowDel(row);
                      }}
                    ></i>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="hide-on-print">
            <Pagination
              length={list.data?.totalRecords || 0}
              page={paging.page}
              pageSize={paging.pageSize}
              pageChange={pageChange}
              showingOfWhatLabel="Definitions"
              sizes={[10, 15, 25, 50, 100]}
            ></Pagination>
          </div>
        </>
      )}
    </div>
  );
}

export default ImportExportDefinitions;
