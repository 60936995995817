import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import { useCompanySettings, useLastPageFilters } from "../stores/SystemStore";
import commonService from "../services/CommonService";
import SysModels from "../models";
import PickTemplateDialog from "./PickTemplateDialog";

function Entities(props: { viewOnly?: boolean }) {
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(
    async () =>
      (props.viewOnly
        ? SysServices.http.entity.list
        : SysServices.http.entity.listReminderManager)(
        paging.page,
        paging.pageSize,
        {
          search: search.used,
        }
      ),
    "Entities"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready]);

  const [showPickTemp, setShowPickTemp] = useState(false);

  const types = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("WF1FieldType"),
    "Field Types"
  );

  useEffect(() => {
    types.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companySettings = useCompanySettings();

  return (
    <div>
      <div className="mb-3">
        <h4>
          {props.viewOnly
            ? "View Reminders"
            : companySettings?.entityLabelPlural}
        </h4>
      </div>
      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {showPickTemp && (
        <PickTemplateDialog onClose={() => setShowPickTemp(false)} />
      )}
      {pageFilters.ready && (
        <>
          <div className="flex flex-wrap gap-10 mb-3">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-1">
              {!props.viewOnly && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => {
                    setShowPickTemp(true);
                  }}
                >
                  Add {companySettings?.entityLabelSingular}
                </button>
              )}
            </div>
            <div className="flex-1"></div>
          </div>

          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Template</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th className="no-wrap">Fields / Type / Value</th>
                  </tr>
                </thead>
                <tbody>
                  {!list.data?.totalRecords && (
                    <tr>
                      <td colSpan={4}>No Record(s) Found</td>
                    </tr>
                  )}
                  {list?.data?.entities?.map((field) => (
                    <tr
                      key={field.id}
                      className="pointer"
                      onClick={(e) => {
                        if (props.viewOnly) {
                          nav(`/view-reminders/${field.id}`);
                        } else {
                          nav(`/cards/${field.id}`);
                        }
                      }}
                    >
                      <td>{field.entityTemplateName}</td>
                      <td>
                        {[field.lastName, field.firstName]
                          .filter((x) => !!x)
                          .join(", ")}
                      </td>
                      <td>{field.email}</td>
                      <td>
                        <table className="table table-sm table-bordered mb-0">
                          <tbody>
                            {field.fields
                              ?.sort(
                                commonService.sortByNumericProperty("sequence")
                              )
                              ?.map((fd) => (
                                <tr key={fd.sequence}>
                                  <td
                                    style={{ width: "20%", minWidth: "100px" }}
                                  >
                                    {fd.name}
                                  </td>
                                  <td style={{ width: "90px" }}>
                                    {
                                      types.data?.find(
                                        (t) => t.value === fd.fieldType
                                      )?.label
                                    }
                                  </td>
                                  <td>
                                    {fd.value ? (
                                      <>
                                        {fd.fieldType ===
                                        SysModels.WF1FieldType.Date ? (
                                          <>
                                            {commonService.toDateString(
                                              fd.value,
                                              "full"
                                            )}
                                          </>
                                        ) : (
                                          <>{fd.value}</>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="Entities"
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Entities;
