import SysModels from "../../models";
import fetcher from "../Fetcher";

class CompanyService {
  get = async (id: string) => {
    const url = `/Company/${id}`;
    return fetcher.get<SysModels.CompanyOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.CompanySearchDto
  ) => {
    const url = `/Company/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.CompanyGridDto>(url, model);
  };

  create = async (model: SysModels.CompanyCreateDto) => {
    const url = `/Company`;
    return fetcher.post<any>(url, model);
  };

  update = async (id: string, model: SysModels.CompanyUpdateDto) => {
    const url = `/Company/${id}`;
    return fetcher.put<any>(url, model);
  };

  delete = async (id: string) => {
    const url = `/Company/${id}`;
    return fetcher.delete<any>(url);
  };

  loadData = async (id: string) => {
    const url = `/Company/LoadData/${id}`;
    return fetcher.put<any>(url);
  };

  getForCompanyAdmin = async () => {
    const url = `/Company/CompanyForCompanyAdmin`;
    return fetcher.get<SysModels.CompanyAdminOutputDto>(url);
  };

  updateForAdmin = async (model: SysModels.CompanyUpdateAdminDto) => {
    const url = `/Company/UpdateForAdmin`;
    return fetcher.put<SysModels.CompanyAdminOutputDto>(url, model);
  };

  deleteCompanyLogo = async () => {
    const url = `/Company/DeleteCompanyLogo`;
    return fetcher.delete<any>(url);
  };

  saveCompanyLogo = async (data: FormData) => {
    const url = `/Company/SaveCompanyLogo`;
    return fetcher.postFormData<any>(url, data);
  };

  companyNameLogo = async () => {
    const url = `/Company/CompanyNameLogoSettings`;
    return fetcher.get<SysModels.CompanyNameLogoSettingsDto>(url);
  };

  updateSettingsForCompanyAdmin = async (
    model: SysModels.CompanySettingsDto
  ) => {
    const url = `/Company/UpdateSettingsForCompanyAdmin`;
    return fetcher.put<SysModels.CompanyAdminOutputDto>(url, model);
  };
}

const company = new CompanyService();
export default company;
