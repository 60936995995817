import SysModels from "../../models";
import fetcher from "../Fetcher";

class ManagerSubordinateService {
  get = async (userTypeId: number, managerId: string) => {
    const url = `/ManagerSubordinate/Subordinates/${userTypeId}/${managerId}`;
    return fetcher.get<SysModels.SubordinatesListOutputDto>(url);
  };

  typeAhead = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/ManagerSubordinate/TypeAhead`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  create = async (model: SysModels.SubordinateInputDto) => {
    const url = `/ManagerSubordinate`;
    return fetcher.post<any>(url, model);
  };

  delete = async (data: SysModels.SubordinateInputDto) => {
    const url = `/ManagerSubordinate`;
    return fetcher.delete<any>(url, data);
  };
}

const managerSubordinate = new ManagerSubordinateService();
export default managerSubordinate;
