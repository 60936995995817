import React from "react";

//Use this as hidden component to hold model field data
//for non-input or custom fields
function HiddenInputRequiredRef(props: {
  value: any;
  onFocus?: () => void;
  placeholder: string;
  id?: string;
}) {
  return (
    <input
      id={props.id}
      className="form-control required"
      tabIndex={-1}
      style={{
        opacity: 0,
        position: "absolute",
        width: 0,
        height: 0,
        zIndex: -1,
      }}
      placeholder={props.placeholder}
      value={props.value}
      onFocus={(e) => {
        props.onFocus && props.onFocus();
      }}
      readOnly={true}
    />
  );
}

export default HiddenInputRequiredRef;
