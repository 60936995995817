import React, { useState } from "react";
import { Button } from "react-bootstrap";
import fetcher from "../services/Fetcher";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";

function BlowUpTest(props: any) {
  const [message, setMessage] = useState("");
  const [testing, setTesting] = useState(false);

  return (
    <div>
      <div className="mb-3">
        <h4>Error Test</h4>
      </div>

      <div style={{ maxWidth: "400px", width: "100%" }}>
        <div>
          <div>
            <div className="mb-1">
              <label className="mb-2">Message</label>
              <input
                autoFocus={true}
                className="form-control"
                placeholder="Message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                type="text"
              ></input>
            </div>

            <div className="mt-3 text-right">
              <Button
                variant="primary"
                type="button"
                size="sm"
                className="px-3"
                disabled={testing || commonService.isNullOrEmpty(message)}
                onClick={() => {
                  setTesting(true);
                  fetcher
                    .get(`/Hola/Blowup/${encodeURIComponent(message)}`)
                    .then((data) => {
                      toastStore.showToast("Error Test Sent", "success");
                    })
                    .catch((err) => {
                      toastStore.showError("Error Test", err);
                    })
                    .finally(() => {
                      setTesting(false);
                    });
                }}
              >
                {testing ? "Testing..." : "Test"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlowUpTest;
