import React, { useEffect, useMemo, useState } from "react";
import { useBeforeUnload, useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import SetupReminderDialog, { IReminderSetting } from "./SetupReminderDialog";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import SysModels from "../models";
import ConfirmDialog from "../components/ConfirmDialog";
import ConfirmNavigationDialog from "../components/ConfirmNavigationDialog";

function ReminderEntry(props: { templateId?: string }) {
  const { id } = useParams();
  const templateId = props.templateId || id;
  const nav = useNavigate();

  const reminders = useFetchHelper(
    async () => SysServices.http.reminderSetting.getTemplate(templateId || ""),
    "Reminder Settings"
  );
  const [showDialog, setShowDialog] = useState<IReminderSetting>();

  useEffect(() => {
    reminders.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errors, setErrors] = useState<
    SysModels.ReminderSettingResultErrorDto[]
  >([]);
  const [newlyAdded, setNewlyAdded] = useState<IReminderSetting[]>([]);
  const [toBeDeleted, setToBeDeleted] = useState<IReminderSetting[]>([]);
  const [saving, setSaving] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const save = async () => {
    if (templateId) {
      const items = [
        ...(reminders.data?.reminderSettingDetailOutputDtos || []),
        ...newlyAdded.map((item) => {
          return {
            ...item,
            reminderSettingDetailId: undefined,
          };
        }),
      ].filter(
        (row) =>
          !toBeDeleted.find(
            (r) => r.reminderSettingDetailId === row.reminderSettingDetailId
          )
      );

      setSaving(true);
      await SysServices.http.reminderSetting
        .saveTemplate(templateId, {
          reminderSettingDetailDtos: items,
        })
        .then((rtn) => {
          const newData = {
            objectName: reminders.data?.objectName,
            reminderSettingDetailOutputDtos: rtn.reminderSettingDetailDtos?.map(
              (dto, i) => {
                const match = items.find(
                  (item, ii) =>
                    item.reminderSettingDetailId ===
                      dto.reminderSettingDetailId ||
                    (!item.reminderSettingDetailId && i === ii)
                );
                return {
                  ...dto,
                  fieldName: match?.fieldName,
                  userTypeName: match?.userTypeName,
                };
              }
            ),
          };
          reminders.setData(newData);
          setOrig({ ...newData });
          setToBeDeleted([]);
          setNewlyAdded([]);
          setErrors([...(rtn.reminderSettingResultErrorDtos || [])]);
          if (rtn.reminderSettingResultErrorDtos?.length) {
            toastStore.showToast(
              `<strong>None of the new/changes settings have been saved.</strong><br/>
                Found some problem when saving some of the Reminder Settings. Please check and take appropriate action.`,
              "warning"
            );
          } else {
            toastStore.showToast("Reminder Settings Saved", "success");
          }
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Reminders", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const [orig, setOrig] = useState<SysModels.ReminderSettingOutputDto>();
  useEffect(() => {
    if (reminders.status === FetchStatus.Complete) {
      setOrig({ ...reminders.data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders.status]);

  const hasUnsaved = useMemo(() => {
    return (
      newlyAdded.length > 0 ||
      toBeDeleted.length > 0 ||
      JSON.stringify(reminders.data) !== JSON.stringify(orig)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyAdded, toBeDeleted, reminders.data, orig]);

  return (
    <div className="mt-2">
      {!props.templateId && (
        <div className="mb-3">
          <h4>Template Reminders for {reminders?.data?.objectName || "..."}</h4>
        </div>
      )}
      <ConfirmNavigationDialog hasChanges={hasUnsaved} />
      <ConfirmDialog
        show={confirmDelete}
        title="Confirmation"
        message="Some reminder settings will be deleted, do you want to continue?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            save();
          }
          setConfirmDelete(false);
        }}
      ></ConfirmDialog>

      {!!showDialog && templateId && (
        <SetupReminderDialog
          data={showDialog}
          onClose={() => {
            setShowDialog(undefined);
          }}
          template={{ isUserTemplate: false, templateId: templateId }}
          done={(data) => {
            if (`${data.reminderSettingDetailId || ""}`?.indexOf("new") > -1) {
              if (
                newlyAdded.find(
                  (r) =>
                    r.reminderSettingDetailId === data.reminderSettingDetailId
                )
              ) {
                setNewlyAdded((prev) => {
                  return [...prev].map((r) => {
                    if (
                      r.reminderSettingDetailId === data.reminderSettingDetailId
                    ) {
                      return data;
                    }
                    return r;
                  });
                });
              } else {
                setNewlyAdded((prev) => {
                  return [...prev, data];
                });
              }
            } else {
              if (
                reminders.data?.reminderSettingDetailOutputDtos?.find(
                  (r) =>
                    r.reminderSettingDetailId === data.reminderSettingDetailId
                )
              ) {
                reminders.setData((prev) => {
                  return {
                    ...prev,
                    reminderSettingDetailOutputDtos: (
                      reminders.data?.reminderSettingDetailOutputDtos || []
                    ).map((r) => {
                      if (
                        r.reminderSettingDetailId ===
                        data.reminderSettingDetailId
                      ) {
                        return data;
                      }
                      return r;
                    }),
                  };
                });
              }
            }
          }}
        />
      )}

      <div>
        <table className="table table-hover table-bordered">
          <thead>
            <tr>
              <td className="text-center"></td>
              <td style={{ width: "40%" }}>Who to Remind</td>
              <td style={{ width: "40%" }}>When to Remind</td>
              <td style={{ width: "20%" }}>Warnings</td>
              <td className="text-center">Actions</td>
            </tr>
          </thead>
          <tbody>
            {![
              ...(reminders.data?.reminderSettingDetailOutputDtos || []),
              ...newlyAdded,
            ].length && (
              <tr>
                <td colSpan={5} className="text-center">
                  No Record(s) Found
                </td>
              </tr>
            )}
            {[
              ...(reminders.data?.reminderSettingDetailOutputDtos || []),
              ...newlyAdded,
            ].map((row, i) => (
              <tr
                key={`${row.reminderSettingDetailId}-${i}`}
                className="pointer"
                onClick={(e) => {
                  setShowDialog(row);
                }}
              >
                <td className="text-center">
                  <span className="pe-2">
                    {errors.find(
                      (x) =>
                        x.firstId === row.reminderSettingDetailId ||
                        x.secondId === row.reminderSettingDetailId
                    )?.error ? (
                      <>
                        <i className="fa fa-warning text-danger"></i>
                      </>
                    ) : (
                      <>
                        {`${row.reminderSettingDetailId || ""}`.indexOf("new") >
                        -1 ? (
                          <>
                            <span className="chip-green">New</span>
                          </>
                        ) : (
                          <>{i + 1}</>
                        )}
                      </>
                    )}
                  </span>
                </td>
                <td
                  className={
                    toBeDeleted.find(
                      (r) =>
                        r.reminderSettingDetailId ===
                        row.reminderSettingDetailId
                    )
                      ? "strike text-danger"
                      : ""
                  }
                >
                  {[
                    row.notifyEntityUser ? "Notify Entity User" : "",
                    row.userTypeId ? `Group: ${row.userTypeName}` : "",
                    row.otherUserEmailAddresses
                      ? row.otherUserEmailAddresses
                      : "",
                  ]
                    .filter((f) => !commonService.isNullOrEmpty(f))
                    ?.join("")}
                </td>
                <td
                  className={
                    toBeDeleted.find(
                      (r) =>
                        r.reminderSettingDetailId ===
                        row.reminderSettingDetailId
                    )
                      ? "strike text-danger"
                      : ""
                  }
                >
                  {[
                    row.atTimeOfAssignment ? "At time of assignment" : "",
                    row.fieldId
                      ? `Field: ${row.fieldName}, ${[
                          row.daysBefore
                            ? `Days Before: ${row.daysBefore}`
                            : "",
                          row.daysAfter ? `Days After: ${row.daysAfter}` : "",
                        ].join(",")}`
                      : "",
                  ]
                    .filter((f) => !commonService.isNullOrEmpty(f))
                    ?.join("")}
                </td>
                <td>
                  {errors
                    .filter(
                      (x) =>
                        x.firstId === row.reminderSettingDetailId ||
                        x.secondId === row.reminderSettingDetailId
                    )
                    ?.map((x, i) => (
                      <small key={i} className="text-danger">
                        {x.error}
                      </small>
                    ))}
                </td>
                <td className="text-center">
                  {toBeDeleted.find(
                    (r) =>
                      r.reminderSettingDetailId === row.reminderSettingDetailId
                  ) ? (
                    <i
                      className="mx-3 fa fa-undo text-primary pointer"
                      title="Undo"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setToBeDeleted((prev) => {
                          return [...prev].filter(
                            (r) =>
                              r.reminderSettingDetailId !==
                              row.reminderSettingDetailId
                          );
                        });
                      }}
                    ></i>
                  ) : (
                    <i
                      className="mx-3 fa fa-trash text-danger pointer"
                      title="Delete"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setToBeDeleted((prev) => {
                          return [...prev, row];
                        });
                      }}
                    ></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-wrap gap-10 mb-3">
        <div className="pt-3 flex-1">
          <button
            className="btn btn-success no-wrap me-2"
            type="button"
            onClick={(e) => {
              setShowDialog({});
            }}
            disabled={saving}
          >
            Add Row
          </button>
        </div>
        <div className="pt-3 text-right">
          {!props.templateId && (
            <button
              className="btn btn-secondary me-2"
              type="button"
              onClick={(e) => {
                nav("/template-reminders");
              }}
            >
              Back
            </button>
          )}
          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) => {
              if (toBeDeleted.length > 0) {
                setConfirmDelete(true);
              } else {
                save();
              }
            }}
            disabled={saving}
          >
            {saving ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReminderEntry;
