import React, { useEffect, useState } from "react";
import FormModal from "../components/FormModal";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import SysModels from "../models";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import SwitchButton from "../components/SwitchButton";
import CommonSpinner from "../components/CommonSpinner";

function ImportExportDefinitionsDialiog(props: {
  entityTemplateId: string;
  fields: SysModels.EntityTemplateFieldOutputDto[];
  id?: string;
  onClose: () => void;
  done: (data: any) => void;
}) {
  const [model, setModel] = useState<
    SysModels.ImportExportInputCreateDto & SysModels.ImportExportOutputDto
  >({
    entityTemplateId: props.entityTemplateId,
    isImportDefinition: false,
  });
  const definition = useFetchHelper(
    async () =>
      SysServices.http.importExportDefinition.get(
        props.id || "",
        props.entityTemplateId
      ),
    "Definition"
  );

  useEffect(() => {
    if (props.id) {
      definition.getData();
    } else {
      commonService.focusInput("defname", 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.id && definition.status === FetchStatus.Complete) {
      definition.data && setModel(definition.data);
      commonService.focusInput("defname", 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definition.status]);

  const validateForm = () => {
    const err = commonService.getFormErrors("defForm");
    if (err.length) {
      toastStore.showToast(err[0], "warning");
    }
    return err.length === 0;
  };

  const [saving, setSaving] = useState(false);

  return (
    <FormModal
      isOpen={true}
      title={`${props.id ? "Edit" : "Add"} Definition`}
      size="xl"
      disableSubmit={definition.status === FetchStatus.InProgress || saving}
      submitButtonLabel={saving ? "Saving..." : "Submit"}
      close={() => {
        props.onClose();
      }}
      deleteAction={() => {}}
      submit={() => {
        if (validateForm() === true) {
          setSaving(true);
          if (props.id) {
            SysServices.http.importExportDefinition
              .update(props.id, props.entityTemplateId, model)
              .then((data) => {
                toastStore.showToast(
                  `${
                    model.isImportDefinition ? "Import" : "Export"
                  } Definition Saved`,
                  "success"
                );
                props.done(model);
              })
              .catch((err) => {
                toastStore.showError("Failed Saving Definition", err);
              })
              .finally(() => {
                setSaving(false);
              });
          } else {
            SysServices.http.importExportDefinition
              .create(model)
              .then((data) => {
                toastStore.showToast(
                  `${
                    model.isImportDefinition ? "Import" : "Export"
                  } Definition Saved`,
                  "success"
                );
                props.done(model);
              })
              .catch((err) => {
                toastStore.showError("Failed Saving Definition", err);
              })
              .finally(() => {
                setSaving(false);
              });
          }
        }
      }}
    >
      <div>
        {definition.status === FetchStatus.InProgress && (
          <CommonSpinner message="Loading..."></CommonSpinner>
        )}
        <div
          id="defForm"
          className={`row ${
            definition.status === FetchStatus.InProgress ? "display-none" : ""
          }`}
        >
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="mb-2">
              <div className="mb-1">
                <label className="required-label">Name</label>
              </div>
              <input
                id="defname"
                className="form-control required"
                type="text"
                autoFocus={true}
                autoComplete="new-password"
                value={model.name}
                placeholder="Name"
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="mb-2">
              <div className="mb-1">
                <label className="required-label">Description</label>
              </div>
              <textarea
                className="form-control required"
                rows={3}
                autoComplete="new-password"
                value={model.description}
                placeholder="Description"
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="py-2">
              <div>
                <SwitchButton
                  uncheckedLabel="Is Import?"
                  checked={model.isImportDefinition || false}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        isImportDefinition: val || false,
                      };
                    });
                  }}
                  disabled={!!model.id}
                ></SwitchButton>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8 mb-2">
            <div className="mb-1">
              <label>Mapping</label>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Template Field</th>
                  <th>CSV Column</th>
                  <th>Format</th>
                </tr>
              </thead>
              <tbody>
                {props.fields
                  ?.sort(commonService.sortByNumericProperty("sequence"))
                  ?.map((field) => (
                    <tr key={field.id}>
                      <td>
                        <div className="flex gap-10 flex-center pt-2">
                          <span>{field.name}</span>
                          {field.isFieldRequired && (
                            <span className="chip-red">Required</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className={`form-control ${
                            field.isFieldRequired ? "required" : ""
                          }`}
                          placeholder={`CSV Column for '${field.name}'`}
                          autoComplete="new-password"
                          value={
                            model.importExportColumDefinitionDtos?.find(
                              (x) => x.fieldId === field.id
                            )?.columnName || ""
                          }
                          onChange={(e) => {
                            setModel((prev) => {
                              const match =
                                prev.importExportColumDefinitionDtos?.find(
                                  (x) => x.fieldId === field.id
                                );
                              if (match) {
                                return {
                                  ...prev,
                                  importExportColumDefinitionDtos: (
                                    prev.importExportColumDefinitionDtos || []
                                  ).map((f) => {
                                    if (f.fieldId === field.id) {
                                      return {
                                        ...f,
                                        columnName: e.target.value,
                                      };
                                    }
                                    return f;
                                  }),
                                };
                              } else {
                                return {
                                  ...prev,
                                  importExportColumDefinitionDtos: [
                                    ...(prev.importExportColumDefinitionDtos ||
                                      []),
                                    {
                                      fieldId: field.id,
                                      columnName: e.target.value || "",
                                      dateFormat: "MM-dd-yyyy",
                                    },
                                  ],
                                };
                              }
                            });
                          }}
                        />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          disabled={
                            field.fieldType !== SysModels.WF1FieldType.Date
                          }
                          style={
                            field.fieldType === SysModels.WF1FieldType.Date
                              ? {}
                              : { opacity: 0.5, color: "transparent" }
                          }
                          value={
                            model.importExportColumDefinitionDtos?.find(
                              (x) => x.fieldId === field.id
                            )?.dateFormat || ""
                          }
                          onChange={(e) => {
                            setModel((prev) => {
                              const match =
                                prev.importExportColumDefinitionDtos?.find(
                                  (x) => x.fieldId === field.id
                                );
                              if (match) {
                                return {
                                  ...prev,
                                  importExportColumDefinitionDtos: (
                                    prev.importExportColumDefinitionDtos || []
                                  ).map((f) => {
                                    if (f.fieldId === field.id) {
                                      return {
                                        ...f,
                                        dateFormat: e.target.value,
                                      };
                                    }
                                    return f;
                                  }),
                                };
                              } else {
                                return {
                                  ...prev,
                                  importExportColumDefinitionDtos: [
                                    ...(prev.importExportColumDefinitionDtos ||
                                      []),
                                    {
                                      fieldId: field.id,
                                      columnName: "",
                                      dateFormat: e.target.value || "",
                                    },
                                  ],
                                };
                              }
                            });
                          }}
                        >
                          <option value="MM-dd-yyyy">MM-dd-yyyy</option>
                          <option value="MM/dd/yyyy">MM/dd/yyyy</option>
                          <option value="dd-MM-yyyy">dd-MM-yyyy</option>
                          <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                          <option value="yyyy-MM-dd">yyyy-MM-dd</option>
                          <option value="yyyy/MM/dd">yyyy/MM/dd</option>
                        </select>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default ImportExportDefinitionsDialiog;
