import { makeAutoObservable } from "mobx";
import commonService from "../services/CommonService";
import systemStore from "./SystemStore";

export type ToastTypes = "info" | "success" | "warning" | "error";
export interface IToast {
  id?: number;
  message: string;
  type: "info" | "success" | "warning" | "error";
  duration?: number;
  tmo?: any;
  remove?: boolean;
}

class ToastStore {
  private _cnt = 0;

  constructor() {
    makeAutoObservable(this);
  }

  private _items: IToast[] = [];

  get items() {
    return this._items;
  }

  clearAll() {
    this.items.forEach((item) => {
      clearTimeout(item.tmo);
      this.removeToast(item);
    });
  }

  toggleHover(hover = false) {
    if (hover) {
      this.items.forEach((item) => {
        clearTimeout(item.tmo);
      });
    } else {
      this.items.forEach((item) => {
        item.tmo = setTimeout(() => {
          this.removeToast(item);
        }, item.duration);
      });
    }
  }

  async showError(message: string, error: any, duration = 5000) {
    //const is403 = commonService.getErrorStatusCode(error);
    //console.log(`is403: ${is403}`);

    const errSeverity = commonService.getErrorSeverityEnum(error);
    const errCode = commonService.getErrorStatusCode(error);

    const text = `${message} ${commonService.parseErrorMessage(error)}`;
    console.log(text);

    if (text.indexOf("Failed to Refresh Token") > -1) {
      return; //do nothing...
    }

    //Ticket #262
    if (isNaN(errCode) || errCode === 500 || errCode === 404) {
      //Continue...
    } else {
      systemStore.setErrorDialog(
        true,
        message,
        commonService.parseErrorMessage(error),
        errSeverity
      );
      return; //do nothing...
    }

    const html = `<strong>${message}</strong><br/>
    ${commonService.parseErrorMessage(error)}`;
    return await this.showToast(html, "error", duration);
  }

  async showToast(message: string, type: ToastTypes = "info", duration = 5000) {
    this._cnt++;
    const tmo = setTimeout(() => {
      this.removeToast(item);
    }, duration);
    const item = {
      id: this._cnt,
      message: message,
      type: type,
      duration: duration,
      tmo: tmo,
    } as IToast;
    this._items.unshift(item);
    return item;
  }

  async removeToast(item: IToast) {
    this._items.forEach((t, i) => {
      if (t.id === item.id) {
        t.remove = true;
      }
    });
    setTimeout(async () => {
      await this._doRemoveToast(item);
    }, 1000);
  }

  private async _doRemoveToast(item: IToast) {
    let idx = -1;
    this._items.forEach((t, i) => {
      if (t.id === item.id) {
        idx = i;
        t.remove = true;
      }
    });
    if (idx > -1) {
      this._items.splice(idx, 1);
    }
  }
}

const toastStore = new ToastStore();
export default toastStore;
