import React, { useEffect, useState } from "react";
import ConfirmDialog from "./ConfirmDialog";
import { useBlocker } from "react-router-dom";

const useLeavePageConfirm = (active = true) => {
  const beforeUnloadListener = (event: any) => {
    event.preventDefault();
    return (event.returnValue = "");
  };

  useEffect(() => {
    if (active) {
      window.addEventListener("beforeunload", beforeUnloadListener);
    } else {
    }
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, [active]);
};

function ConfirmNavigationDialog(props: {
  title?: string;
  message?: string;
  hasChanges: boolean;
}) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      props.hasChanges && currentLocation.pathname !== nextLocation.pathname
  );

  const [show, setShow] = useState(false);
  useEffect(() => {
    if (blocker.state === "blocked") {
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state]);

  useLeavePageConfirm(props.hasChanges);

  return (
    <ConfirmDialog
      show={show}
      title={props.title || "Unsaved Changes"}
      message={
        props.message ||
        "Do you really want to leave this page and discard changes?"
      }
      buttons="yesno"
      done={(rtn) => {
        if (rtn === "yes") {
          blocker.proceed && blocker.proceed();
        } else {
          blocker.reset && blocker.reset();
        }
        setShow(false);
      }}
    ></ConfirmDialog>
  );
}

export default ConfirmNavigationDialog;
