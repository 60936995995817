import React, { useEffect, useState } from "react";
import FormModal from "../components/FormModal";
import SysModels from "../models";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import CommonSpinner from "../components/CommonSpinner";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";

function UserSubordinatesDialog(props: {
  close: (done?: boolean) => void;
  userTypeId: number;
  userTypeName: string;
  managerId: string;
}) {
  const [list, setList] = useState<SysModels.SubordinateOutputDto[]>([]);
  const [listToDel, setListToDel] = useState<SysModels.SubordinateOutputDto[]>(
    []
  );
  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);

    if (list.length > 0) {
      const data: SysModels.SubordinateInputDto = {
        subordinateIdDtos: list,
        userTypeId: props.userTypeId,
        managerId: props.managerId,
      };
      await SysServices.http.managerSubordinate
        .create(data)
        .then((data) => {
          toastStore.showToast("Subordinates Added", "success");
        })
        .catch((err) => {
          toastStore.showError(`Failed Adding Subordinates`, err);
        })
        .finally(() => {});
    }

    if (listToDel.length > 0) {
      const data: SysModels.SubordinateInputDto = {
        subordinateIdDtos: listToDel,
        userTypeId: props.userTypeId,
        managerId: props.managerId,
      };
      await SysServices.http.managerSubordinate
        .delete(data)
        .then((data) => {
          toastStore.showToast("Subordinates Deleted", "success");
        })
        .catch((err) => {
          toastStore.showError(`Failed Deleting Subordinates`, err);
        })
        .finally(() => {});
    }

    setSaving(false);
    props.close();
  };

  const subordinates = useFetchHelper(
    async () =>
      SysServices.http.managerSubordinate.get(
        props.userTypeId,
        props.managerId
      ),
    "Subordinates"
  );

  useEffect(() => {
    subordinates.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {
  //     if (subordinates.status === FetchStatus.Complete) {
  //       setList(subordinates.data?.subordinateOutputDtos || []);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [subordinates.status]);

  const [users, setUsers] = useState([] as any[]);
  const [isLoadingUsers, setIsUserLoading] = useState(false);
  const refUser = React.createRef<any>();
  const handleSearchUsers = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setUsers([]);
      setIsUserLoading(false);
      return;
    }
    setIsUserLoading(true);
    await SysServices.http.managerSubordinate
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setUsers(options);
        setIsUserLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsUserLoading(false);
      });
  };

  const getClass = (id: string) => {
    if (listToDel.find((x) => x.subordinateId === id)) {
      return "alert-danger alert-danger2";
    }
    if (list.find((x) => x.subordinateId === id)) {
      return "alert-success";
    }
    return "alert-secondary";
  };

  return (
    <FormModal
      title="Setup Subordinates"
      size="md"
      isOpen={true}
      close={() => {
        props.close();
      }}
      submit={() => {
        save();
      }}
      submitButtonLabel={saving ? "Saving..." : "Submit"}
      disableSubmit={!list.length && !listToDel.length}
    >
      {subordinates.status === FetchStatus.InProgress && (
        <div>
          <CommonSpinner message="Loading..."></CommonSpinner>
        </div>
      )}
      {subordinates.status === FetchStatus.Complete && (
        <div>
          <div className="flex flex-row alert alert-sm alert-primary">
            <div className="flex-1 pb-1">
              <strong>Manager:</strong>
              <div>{subordinates.data?.managerName}</div>
            </div>
            <div className="flex-1 pb-1">
              <strong>Group:</strong>
              <div>{subordinates.data?.userTypeName}</div>
            </div>
          </div>
          <div>
            <div className="mb-1">
              <label>Subordinates:</label>
            </div>
            <div className="mb-2">
              <AsyncTypeahead
                id="typeahead-search-subordinates"
                labelKey="name"
                renderMenuItemChildren={(option, props, index) => (
                  <TyeaheadItem {...users[index]}></TyeaheadItem>
                )}
                onSearch={handleSearchUsers}
                onChange={(data) => {
                  if (data.length > 0) {
                    const item = data[0] as any;
                    if (
                      [
                        ...list,
                        ...(subordinates.data?.subordinateOutputDtos || []),
                      ].find((x) => x.subordinateId === item.id)
                    ) {
                      if (listToDel.find((x) => x.subordinateId === item.id)) {
                        setListToDel((prev) =>
                          prev.filter((x) => x.subordinateId !== item.id)
                        );
                      } else {
                        toastStore.showToast(
                          `${item.name} is already in the list`,
                          "warning"
                        );
                      }
                    } else {
                      setList((prev) => {
                        return [
                          ...prev,
                          {
                            subordinateId: item.id,
                            name: item.name,
                          },
                        ];
                      });
                    }

                    (refUser.current as any)?.clear();
                  }
                }}
                searchText={"Searching..."}
                isLoading={isLoadingUsers}
                options={users}
                placeholder="Search Subordinates"
                minLength={1}
                delay={500}
                useCache={false}
                filterBy={() => true}
                ref={refUser}
                autoFocus={true}
              />
            </div>
            {[
              ...(subordinates.data?.subordinateOutputDtos || []),
              ...list,
            ]?.map((ut) => (
              <div
                key={ut.subordinateId}
                className={`alert alert-sm p-2 flex flex-center mb-1 ${getClass(
                  ut.subordinateId || ""
                )}`}
                title={ut.name}
              >
                <div
                  className="flex-1 pe-2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {ut.name}
                </div>
                <div className="flex-0">
                  {getClass(ut.subordinateId || "") ===
                  "alert-danger alert-danger2" ? (
                    <i
                      title="Un-delete"
                      className="fa fa-undo text-danger pointer"
                      onClick={(e) => {
                        setListToDel((prev) =>
                          prev.filter(
                            (x) => x.subordinateId !== ut.subordinateId
                          )
                        );
                      }}
                    ></i>
                  ) : (
                    <i
                      title="Delete"
                      className="fa fa-times text-danger pointer"
                      onClick={(e) => {
                        setListToDel((prev) => [...prev, ut]);
                      }}
                    ></i>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </FormModal>
  );
}

export default UserSubordinatesDialog;
