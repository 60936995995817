import SysModels from "../../models";
import fetcher from "../Fetcher";

class HowToService {
  get = async (id: string) => {
    const url = `/HowTo/${id}`;
    return fetcher.get<SysModels.HowToOutputDraftDto>(url);
  };

  create = async (data: SysModels.HowToInputDto) => {
    const url = `/HowTo`;
    return fetcher.post<SysModels.HowToOutputDraftDto>(url, data);
  };

  update = async (id: string, data: SysModels.HowToInputDto) => {
    const url = `/HowTo/${id}`;
    return fetcher.put<SysModels.HowToOutputDraftDto>(url, data);
  };

  delete = async (id: string) => {
    const url = `/HowTo/${id}`;
    return fetcher.delete<any>(url);
  };

  publish = async (id: string) => {
    const url = `/HowTo/Publish/${id}`;
    return fetcher.put<any>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.HowToSearchDto
  ) => {
    const url = `/HowTo/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.HowToGridOutputDto>(url, model);
  };

  listAdmin = async (
    page: number,
    pageSize: number,
    model: SysModels.HowToSearchDto
  ) => {
    const url = `/HowTo/ListAdmin/${page}/${pageSize}`;
    return fetcher.post<SysModels.HowToGridOutputDto>(url, model);
  };

  howToForHomePage = async (data: SysModels.HowToHomePageInputDto) => {
    const url = `/HowTo/HowToForHomePage`;
    return fetcher.post<SysModels.HowToOutputDraftDto>(url, data);
  };
}

const howTo = new HowToService();
export default howTo;
