import React, { useState } from "react";
import FileUpload from "./FileUpload";

function FileDragNDrop(props: {
  label?: string;
  onChange?: (files: FileList) => void;
  disabled?: boolean;
  accepts?: {
    extensions: string;
    pattern: RegExp;
  };
  dropAreaLabel?: string;
}) {
  const [onDrag, setOnDrag] = useState(false);
  const [fileList, setFileList] = useState<FileList>();
  const setDrag = (e: any, drag: boolean) => {
    setOnDrag(drag || false);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={`file-dragndrop ${onDrag ? "is-dragover" : ""}`}
      onDrag={(e) => setDrag(e, true)}
      onDragStart={(e) => setDrag(e, true)}
      onDragOver={(e) => setDrag(e, true)}
      onDragEnter={(e) => setDrag(e, true)}
      onDragEnd={(e) => setDrag(e, false)}
      onDragLeave={(e) => setDrag(e, false)}
      onDrop={(e) => {
        setDrag(e, false);
        if (e.dataTransfer.files) {
          setFileList(e.dataTransfer.files);
        }
      }}
    >
      <FileUpload {...props} filesFromDrop={fileList}></FileUpload>
      <div className="pb-2">
        <i className="fa fa-2x fa-cloud-upload"></i>
        <div>{props.dropAreaLabel || "Drag file here or click to upload"}</div>
      </div>
    </div>
  );
}

export default FileDragNDrop;
