import React, { useEffect, useState } from "react";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { useNavigate, useParams } from "react-router-dom";

function ResetPassword(props: any) {
  const navigate = useNavigate();
  const { token } = useParams();
  const [inProgress, setInProgress] = useState(false);
  const [model, setModel] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
    resetPasswordToken: "",
  });
  const allowed = () => {
    return (
      !commonService.isNullOrEmpty(model.email) &&
      !commonService.isNullOrEmpty(model.newPassword) &&
      !commonService.isNullOrEmpty(model.confirmPassword) &&
      passwordMatch()
    );
  };

  const passwordMatch = () => {
    if (
      !commonService.isNullOrEmpty(model.newPassword) &&
      !commonService.isNullOrEmpty(model.confirmPassword)
    ) {
      return model.newPassword === model.confirmPassword;
    }
    return true;
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    commonService.focusInput("login_username");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="login-page"
      //style={{ backgroundImage: `url(img/bg-login.jpg)` }}
    >
      <div>
        <img alt="Splash" src="/globus-center.png" />
        <h4 className="text-left pt-4 mb-0 text-primary">Reset Password</h4>
        <form
          className="pt-4"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (allowed()) {
              setInProgress(true);
              SysServices.http.myAccount
                .resetPassword({
                  userName: model.email,
                  password: model.newPassword,
                  resetPasswordToken: token || "",
                })
                .then((data: any) => {
                  toastStore.showToast("Password has been updated.", "success");
                  navigate("/");
                })
                .catch((err: any) => {
                  toastStore.showError("Request Failed", err);
                  setInProgress(false);
                });
            }
          }}
        >
          <input
            id="login_username"
            type="input"
            name="email"
            autoComplete="new-password"
            placeholder="Email"
            className="form-control mb-3"
            value={model.email}
            onChange={(e) => {
              setModel((p) => {
                return {
                  ...p,
                  email: e.target.value,
                };
              });
            }}
            disabled={inProgress}
          />
          <input
            type="password"
            name="password"
            placeholder="New Password"
            className="form-control mb-3"
            value={model.newPassword}
            onChange={(e) => {
              setModel((p) => {
                return {
                  ...p,
                  newPassword: e.target.value,
                };
              });
            }}
            disabled={inProgress}
          />
          <input
            type="password"
            name="password"
            placeholder="Confirm New Password"
            className="form-control mb-2"
            value={model.confirmPassword}
            onChange={(e) => {
              setModel((p) => {
                return {
                  ...p,
                  confirmPassword: e.target.value,
                };
              });
            }}
            disabled={inProgress}
          />
          {!passwordMatch() && (
            <div className="text-danger mb-2 text-left">
              <span>New Passwords does not match</span>
            </div>
          )}
          <div className="flex flex-center mt-3">
            <div></div>
            <div className="flex-1 text-right">
              <button
                className="btn btn-secondary me-2"
                type="button"
                disabled={inProgress}
                onClick={(e) => {
                  navigate("/");
                }}
              >
                <span className="px-3">Cancel</span>
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={inProgress || !allowed()}
              >
                <span className="px-3">
                  {inProgress ? "Sending Request..." : "Reset Password"}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
