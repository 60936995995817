import React, { useEffect } from "react";
import NavAnchor from "../components/NavAnchor";
import menuActions from "./MenuActions";
import systemStore, {
  useActiveRole,
  useCompanyLogoUpdated,
  useLoginStatus,
} from "../stores/SystemStore";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";

function SideNav(props: any) {
  const ma = menuActions;
  const loginStatus = useLoginStatus();
  const activeRole = useActiveRole();
  const companyLogoUpdated = useCompanyLogoUpdated();

  const roles = useFetchHelper(
    () => SysServices.http.genericEnumLookup.getRolesEnums(),
    "Roles"
  );

  useEffect(() => {
    roles.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyNameLogo = useFetchHelper(
    async () => SysServices.http.company.companyNameLogo(),
    "Company Info"
  );

  useEffect(() => {
    companyNameLogo.setData(undefined);
    companyNameLogo.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus.companySwitched, companyLogoUpdated]);

  useEffect(() => {
    if (companyNameLogo.status === FetchStatus.Complete) {
      systemStore.setCompanySettings(companyNameLogo.data?.companySettingsDto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyNameLogo.status]);

  return (
    <div className="side-nav-panel">
      {loginStatus.companySwitched && (
        <>
          <div
            className="mb-2 pointer text-primary bg-hover2"
            style={{
              backgroundColor: "#efefef",
            }}
            onClick={(e) => {
              systemStore.setSwitchingCompany(true);
              SysServices.http.myAccount
                .revertBackToParent()
                .then((data) => {
                  SysServices.http.fetcher.storeToken(data);
                  setTimeout(() => {
                    systemStore.setActiveRole("SaaSAdmin");
                    //nav("/companies");
                  }, 100);
                })
                .catch((err) => {
                  toastStore.showError("Failed Switching Back", err);
                })
                .finally(() => {
                  systemStore.setSwitchingCompany(false);
                });
            }}
          >
            <i className="fa fa-sign-out fa-2x me-3"></i>
            <strong>Back to SaaS Admin</strong>
          </div>
        </>
      )}

      {companyNameLogo.data?.companyName && !loginStatus.isParentCompany && (
        <div className="mt-2 flex flex-row flex-center gap-10">
          <div>
            {companyNameLogo.data?.companyLogo ? (
              <img
                src={companyNameLogo.data?.companyLogo}
                style={{ width: "35px" }}
              />
            ) : (
              <i
                className="fa fa-building fa-2x text-primary"
                style={{
                  width: "30px",
                  padding: "5px",
                  borderRadius: "3px",
                }}
              ></i>
            )}
          </div>
          <div className="flex-1 pe-2">
            <strong
              style={{
                whiteSpace: "normal",
                color: "#555",
              }}
            >
              {companyNameLogo.data?.companyName}
            </strong>
          </div>
        </div>
      )}

      <div className="mt-2">
        <strong>ROLES</strong>
      </div>
      <ul className="navbar-nav" key={companyLogoUpdated}>
        <li className="navbar-nav">
          <NavAnchor
            link="/dashboard"
            icon="fa fa-home"
            label="Dashboard"
            disabled={true}
          ></NavAnchor>
        </li>
        {roles.data
          ?.filter((x) => loginStatus.hasRole(x.label))
          ?.map((x) => {
            const action = ma.actions.find(
              (a) =>
                a.dashboard && commonService.isSameRoleName(a.roles[0], x.label)
            );
            return (
              <li className="navbar-nav px-3 pe-0" key={x.value}>
                <NavAnchor
                  active={commonService.isSameRoleName(activeRole, x.label)}
                  link={action?.url || "/dashboard"}
                  icon="fa fa-minus"
                  label={action?.otherLabel?.() || x.label || ""}
                ></NavAnchor>
              </li>
            );
          })}
        <hr />
      </ul>
      <div>
        <strong>
          {
            roles.data?.find((r) =>
              commonService.isSameRoleName(r.label, activeRole)
            )?.label
          }
        </strong>
      </div>
      <ul className="navbar-nav">
        {ma.actions
          .sort(commonService.sortByStringProperty("label"))
          .filter((action) => activeRole && action.roles.includes(activeRole))
          .map((action) => {
            if (
              !action.hidden &&
              (action.roles?.length === 0 ||
                action.roles?.find((r) => loginStatus.hasRole(r)))
            ) {
              return (
                <li
                  className="navbar-nav"
                  key={action.url}
                  title={action.label}
                >
                  <NavAnchor
                    link={action.url}
                    icon={`fa ${action.icon}`}
                    label={action.otherLabel?.() || action.label}
                  ></NavAnchor>
                </li>
              );
            }
            return <React.Fragment key={action.url} />;
          })}
      </ul>
    </div>
  );
}

export default SideNav;
