import React, { useMemo } from "react";
import { useActiveRole, useLoginStatus } from "../stores/SystemStore";
import { useNavigate } from "react-router-dom";
import menuActions from "../layout/MenuActions";
import commonService from "../services/CommonService";

function DashboardIcons(props: {
  showGettingStarted?: boolean;
  onShowGettingStarted?: () => void;
}) {
  const nav = useNavigate();
  const ma = menuActions;
  const loginStatus = useLoginStatus();
  const activeRole = useActiveRole();

  const actions = useMemo(() => {
    return ma.actions
      .sort(commonService.sortByStringProperty("label"))
      .filter(
        (action) =>
          activeRole &&
          loginStatus.hasRole(activeRole) &&
          action.roles.includes(activeRole)
      );
  }, [activeRole, loginStatus]);

  return (
    <div className="dashboard-icons" key={activeRole}>
      {props.showGettingStarted && (
        <div>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onShowGettingStarted?.();
            }}
          >
            <i className={`fa fa-hand-o-right fa-2x`}></i>
            <div>
              <small>Getting Started</small>
            </div>
          </a>
        </div>
      )}
      {actions.map((action, i) => {
        if (
          !action.hidden &&
          (action.roles?.length === 0 ||
            action.roles?.find((r) => loginStatus.hasRole(r)))
        ) {
          return (
            <div key={i + action.url} title={action.label}>
              <a
                href={action.url}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  nav(action.url);
                }}
              >
                <i className={`fa ${action.icon} fa-2x`}></i>
                <div>
                  <small>{action.otherLabel?.() || action.label}</small>
                </div>
              </a>
            </div>
          );
        }
        return <React.Fragment key={action.url} />;
      })}
    </div>
  );
}

export default DashboardIcons;
