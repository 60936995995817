import SysModels from "../../models";
import fetcher from "../Fetcher";

class EntityTemplateService {
  get = async (id: string) => {
    const url = `/EntityTemplate/${id}`;
    return fetcher.get<SysModels.EntityTemplateOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.EntityTemplateSearchInputDto
  ) => {
    const url = `/EntityTemplate/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.EntityTemplateGridOutputDto>(url, model);
  };

  listWithReminders = async (
    page: number,
    pageSize: number,
    model: SysModels.EntityTemplateReminderSearchInputDto
  ) => {
    const url = `/EntityTemplate/ListWithReminders/${page}/${pageSize}`;
    return fetcher.post<SysModels.EntityTemplateGridWithReminderOutputDto>(
      url,
      model
    );
  };

  typeAhead = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/EntityTemplate/TypeAhead`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  typeAheadForEntity = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/EntityTemplate/TypeAheadForEntity`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  typeAheadForUser = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/EntityTemplate/TypeAheadForUser`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  create = async (model: SysModels.EntityTemplateInputDto) => {
    const url = `/EntityTemplate`;
    return fetcher.post<SysModels.EntityTemplateOutputDto>(url, model);
  };

  update = async (id: string, model: SysModels.EntityTemplateInputDto) => {
    const url = `/EntityTemplate/${id}`;
    return fetcher.put<SysModels.EntityTemplateOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/EntityTemplate/${id}`;
    return fetcher.delete<any>(url);
  };

  updateSequence = async (
    id: string,
    model: SysModels.EntityFieldSequenceDto[]
  ) => {
    const url = `/EntityTemplate/UpdateSequence/${id}`;
    return fetcher.put<any>(url, model);
  };

  deleteField = async (entityId: string, fieldId: number) => {
    const url = `/EntityTemplate/DeleteField/${entityId}/${fieldId}`;
    return fetcher.delete<any>(url);
  };

  getEntityTemplateForReminder = async (id: string) => {
    const url = `/EntityTemplate/EntityTemplateForReminder/${id}`;
    return fetcher.get<SysModels.EntityTemplateOutputDto>(url);
  };

  getEntityTemplateForEntity = async (id: string) => {
    const url = `/EntityTemplate/EntityTemplateForEntity/${id}`;
    return fetcher.get<SysModels.EntityTemplateOutputDto>(url);
  };

  addMultipleTemplatesToCategory = async (
    model: SysModels.MultipleTemplatesForCategoryInputDto
  ) => {
    const url = `/EntityTemplate/AddMultipleTemplatesToCategory`;
    return fetcher.post<SysModels.MultipleTemplatesForCategoryListOutputDto>(
      url,
      model
    );
  };

  searchTemplatesForCategory = async (
    model: SysModels.EntityTemplateSearchInputDto
  ) => {
    const url = `/EntityTemplate/SearchTemplatesForCategory`;
    return fetcher.post<SysModels.TemplateCategorySearchOutputDto[]>(
      url,
      model
    );
  };

  allowFullTemplate = async () => {
    const url = `/EntityTemplate/AllowFullTemplate`;
    const data = fetcher.getCachedData(url);
    if (data) {
      return await new Promise<boolean>((resolve) => {
        setTimeout(() => resolve(data), 100);
      });
    }
    return fetcher.get<boolean>(url).then((data) => {
      fetcher.setCachedData(url, data);
      return data;
    });
  };
}

const entityTemplate = new EntityTemplateService();
export default entityTemplate;
