import React, { useEffect } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import { useActiveRole } from "../stores/SystemStore";
import SysServices from "../services";
import commonService from "../services/CommonService";
import DocEditor from "./DocEditor";
import CommonSpinner from "./CommonSpinner";

function DashboardHowTo(props: { onHide: (noHowTo?: boolean) => void }) {
  const activeRole = useActiveRole();

  const roles = useFetchHelper(
    () => SysServices.http.genericEnumLookup.getRolesEnums(),
    "Roles"
  );

  const howTo = useFetchHelper(
    async () =>
      SysServices.http.howTo.howToForHomePage({
        role: roles.data?.find((r) =>
          commonService.isSameRoleName(r.label, activeRole)
        )?.value,
      }),
    "How To"
  );

  useEffect(() => {
    roles.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (roles.status === FetchStatus.Complete) {
      howTo.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole, roles.status]);

  useEffect(() => {
    if (howTo.status === FetchStatus.Complete) {
      if (!howTo.data?.displayOnHomePage) {
        props.onHide(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [howTo.status]);

  return (
    <div className="dashboard-howto">
      {(roles.status === FetchStatus.InProgress ||
        howTo.status === FetchStatus.InProgress) && (
        <CommonSpinner></CommonSpinner>
      )}
      {howTo.status === FetchStatus.Complete && (
        <>
          <h5 className="mb-1 flex flex-row">
            <span className="flex-1">{howTo.data?.title}</span>
            <span>
              <i
                className="fa fa-eye-slash pointer text-secondary"
                title="Hide"
                onClick={(e) => {
                  props.onHide();
                }}
              ></i>
            </span>
          </h5>
          <div className="mb-4">
            <small>
              {commonService.toDateString(howTo.data?.publishedOn, "full")}
            </small>
          </div>
          <div className="document-body">
            <div>
              <DocEditor
                key="published-editor"
                content={howTo.data?.body || ""}
                onChange={(val) => {}}
                onSave={() => {}}
                onDialogOpen={(open) => {}}
                readonly={true}
              ></DocEditor>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DashboardHowTo;
