import React from "react";
import SysModels from "../models";
import commonService from "../services/CommonService";

function NotificationsTab({
  list,
}: {
  list: SysModels.NotificationOutputDetailDto[];
}) {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date To Send</th>
            <th>At Time of Assignment</th>
            <th>Can Be Sent</th>
            <th>Field Name</th>
            <th>Day(s) Before</th>
            <th>Day(s) After</th>
            <th>User Type</th>
            <th>Email Address</th>
            <th>Email Sent To</th>
          </tr>
        </thead>
        <tbody>
          {!list.length && (
            <tr>
              <td className="text-center" colSpan={9}>
                No Record(s)
              </td>
            </tr>
          )}
          {list.map((row) => (
            <tr key={row.id}>
              <td>{commonService.toLocalDate(row.dateToSend, "full")}</td>
              <td>
                {row.atTimeOfAssignment ? (
                  <span className="chip-green">Yes</span>
                ) : (
                  <span className="chip-red">No</span>
                )}
              </td>
              <td>
                {row.canBeSent ? (
                  <span className="chip-green">Yes</span>
                ) : (
                  <span className="chip-red">No</span>
                )}
              </td>
              <td>{row.fieldName}</td>
              <td>{row.dayAfter}</td>
              <td>{row.dayBefore}</td>
              <td>{row.userTypeName}</td>
              <td>{row.emailAddress}</td>
              <td>{row.emailSentTo}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default NotificationsTab;
