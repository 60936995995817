import SysModels from "../../models";
import fetcher from "../Fetcher";

class UserService {
  get = async (id: string) => {
    const url = `/User/${id}`;
    return fetcher.get<SysModels.UserOutputDto>(url);
  };

  getUserTemplate = async (id: string) => {
    const url = `/User/UserTemplate/${id}`;
    return fetcher.get<SysModels.UserOutputForReminderManagerDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.UserSearchInputDto
  ) => {
    const url = `/User/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.UserGridOutputDto>(url, model);
  };

  listUserTemplates = async (
    page: number,
    pageSize: number,
    model: SysModels.UserSearchReminderManagerInputDto
  ) => {
    const url = `/User/ListUserTemplates/${page}/${pageSize}`;
    return fetcher.post<SysModels.UserGridReminderManagerOutputDto>(url, model);
  };

  typeAhead = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/User/TypeAhead`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  create = async (model: SysModels.UserCreateDto) => {
    const url = `/User`;
    return fetcher.post<SysModels.UserOutputDto>(url, model);
  };

  update = async (userName: string, model: SysModels.UserUpdateDto) => {
    const url = `/User/${userName}`;
    return fetcher.put<SysModels.UserOutputDto>(url, model);
  };

  sendPasswordViaEmail = async (userName: string) => {
    const url = `/User/SendPasswordViaEmail/${userName}`;
    return fetcher.post<any>(url);
  };

  saveUserTemplate = async (
    userName: string,
    model: SysModels.UserTemplateInputDto
  ) => {
    const url = `/User/SaveUserTemplate/${userName}`;
    return fetcher.put<any>(url, model);
  };

  removeUserTemplate = async (userName: string) => {
    const url = `/User/RemoveUserTemplate/${userName}`;
    return fetcher.delete<any>(url);
  };
}

const user = new UserService();
export default user;
