import SysModels from "../../models";
import fetcher from "../Fetcher";

class EntityFieldService {
  any = async () => {
    const url = `/EntityField/Any`;
    return fetcher.get<boolean>(url);
  };

  get = async (id: string) => {
    const url = `/EntityField/${id}`;
    return fetcher.get<SysModels.EntityFieldOutputDto>(url);
  };

  typeAheadDateOnly = async (data: SysModels.EntityFieldTypeAheadDateOnly) => {
    const url = `/EntityField/TypeAheadDateOnly`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, data);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.EntityFieldSearchInputDto
  ) => {
    const url = `/EntityField/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.EntityFieldGridOutputDto>(url, model);
  };

  typeAhead = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/EntityField/TypeAhead`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  create = async (model: SysModels.EntityFieldInputDto) => {
    const url = `/EntityField`;
    return fetcher.post<SysModels.EntityFieldOutputDto>(url, model);
  };

  update = async (id: number, model: SysModels.EntityFieldInputDto) => {
    const url = `/EntityField/${id}`;
    return fetcher.put<any>(url, model);
  };

  delete = async (id: number) => {
    const url = `/EntityField/${id}`;
    return fetcher.delete<any>(url);
  };

  finishReminderTrigger = async () => {
    const url = `/EntityField/FinishReminderTrigger`;
    return fetcher.get<SysModels.EntityFieldOutputDto>(url);
  };
}

const entityField = new EntityFieldService();
export default entityField;
