import SysModels from "../../models";
import fetcher from "../Fetcher";

class ReminderSettingService {
  getCategory = async (id: string) => {
    const url = `/ReminderSetting/Category/${id}`;
    return fetcher.get<SysModels.ReminderSettingOutputDto>(url);
  };

  saveCategory = async (id: string, data: SysModels.ReminderSettingDto) => {
    const url = `/ReminderSetting/Category/${id}`;
    return fetcher.put<SysModels.ReminderSettingResultDto>(url, data);
  };

  getTemplate = async (id: string) => {
    const url = `/ReminderSetting/Template/${id}`;
    return fetcher.get<SysModels.ReminderSettingOutputDto>(url);
  };

  saveTemplate = async (id: string, data: SysModels.ReminderSettingDto) => {
    const url = `/ReminderSetting/Template/${id}`;
    return fetcher.put<SysModels.ReminderSettingResultDto>(url, data);
  };
}

const reminderSetting = new ReminderSettingService();
export default reminderSetting;
