import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import SysModels from "../models";
import DateTimePicker from "../components/DateTimePicker";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import ConfirmDialog from "../components/ConfirmDialog";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";
import ActivityLogs from "../components/ActivityLogs";
import CommonSpinner from "../components/CommonSpinner";
import { useCompanySettings, useLoadedTab } from "../stores/SystemStore";
import { Tab, Tabs } from "react-bootstrap";
import NotificationsTab from "../components/NotificationsTab";

function Entity(props: { viewOnly?: boolean }) {
  const nav = useNavigate();
  const { id, template } = useParams();
  const companySettings = useCompanySettings();

  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState<
    SysModels.EntityInputDto & { userFullName?: string }
  >({
    fields: [],
  });

  const entityTemplate = useFetchHelper(
    () =>
      SysServices.http.entityTemplate.getEntityTemplateForEntity(
        template || ""
      ),
    "Template"
  );

  const entity = useFetchHelper(
    () =>
      (props.viewOnly
        ? SysServices.http.entity.get
        : SysServices.http.entity.getEntityReminderManager)(id || ""),
    companySettings?.entityLabelSingular || "Entity"
  );

  useEffect(() => {
    if (template) {
      entityTemplate.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (!entity.data?.id && id && id !== "new") {
      entity.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (entity.status === FetchStatus.Complete && entity.data) {
      setModel({
        ...entity.data,
        userFullName: `${entity.data.lastName || ""}, ${
          entity.data.firstName || ""
        }`,
      });

      entityTemplate.setData({
        ...entity.data,
        fields: entity.data.fields?.map((f) => {
          return {
            ...f,
            id: f.entityFieldId,
            sequence: f.sequence,
          };
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.status]);

  const setFieldValue = (fieldId: any, value: any) => {
    setModel((prev) => {
      return {
        ...prev,
        fields: (prev.fields || []).find((f) => f.entityFieldId === fieldId)
          ? [
              ...(prev.fields || []).map((f) => {
                if (f.entityFieldId === fieldId) {
                  return {
                    ...f,
                    value: value,
                  };
                }
                return f;
              }),
            ]
          : [
              ...(prev.fields || []),
              {
                entityFieldId: fieldId,
                value: value,
              },
            ],
      };
    });
  };

  const getFiedlValues = (fieldId: any) => {
    return (model.fields || []).find((f) => f.entityFieldId === fieldId)?.value;
  };

  const getFieldValueList = () => {
    if (entityTemplate.data?.fields?.length) {
      return entityTemplate.data.fields.map((f) => {
        const val = getFiedlValues(f.id);
        return {
          entityFieldId: f.id,
          value: val === undefined ? (null as any) : val,
        };
      });
    }
    return model.fields?.map((f) => {
      const val = getFiedlValues(f.entityFieldId);
      return {
        entityFieldId: f.entityFieldId,
        value: val === undefined ? (null as any) : val,
      };
    });
  };

  const validateForm = () => {
    const err = commonService.getFormErrors("entityForm");
    if (err.length) {
      toastStore.showToast(err[0], "warning");
    }
    return err.length === 0;
  };

  const save = () => {
    if (!validateForm()) return;

    setSaving(true);
    if (entity.data?.id) {
      SysServices.http.entity
        .update(entity.data?.id, {
          ...model,
          fields: getFieldValueList(),
        })
        .then((data) => {
          toastStore.showToast(
            `${companySettings?.entityLabelSingular} Saved.`,
            "success"
          );
          entity.setDataAndComplete(data);
        })
        .catch((err) => {
          toastStore.showError(
            `Failed Saving ${companySettings?.entityLabelSingular}`,
            err
          );
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      SysServices.http.entity
        .create({
          ...model,
          entityTemplateId: entityTemplate.data?.id,
          fields: getFieldValueList(),
        })
        .then((data) => {
          toastStore.showToast(
            `${companySettings?.entityLabelSingular} Saved.`,
            "success"
          );
          if (data.id) {
            entity.setDataAndComplete(data);
            nav(`/cards/${data.id}`, { replace: true });
          }
        })
        .catch((err) => {
          toastStore.showError(
            `Failed Saving ${companySettings?.entityLabelSingular}`,
            err
          );
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const [itemUser, setItemUser] = useState([] as any[]);
  const [isLoadingUsers, setIsUserLoading] = useState(false);
  const refUser = React.createRef<any>();
  const handleSearchUsers = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setItemUser([]);
      setIsUserLoading(false);
      return;
    }
    setIsUserLoading(true);
    await SysServices.http.user
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemUser(options);
        setIsUserLoading(false);
      })
      .catch((err) => {
        setItemUser([]);
        setIsUserLoading(false);
      });
  };

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const tabs = useLoadedTab(["Detail"]);

  return (
    <div className="tabbed-page">
      <h4>
        {entity.data?.id ? (props.viewOnly ? "View" : "Edit") : "Add"}{" "}
        {companySettings?.entityLabelSingular}:{" "}
        {entityTemplate.data?.name || entity.data?.entityTemplateName}
      </h4>

      <ConfirmDialog
        show={showDel}
        title={`Delete ${companySettings?.entityLabelSingular}`}
        message={`Do you really want to delete this ${companySettings?.entityLabelSingular}?`}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.entity
              .delete(id || "")
              .then((data) => {
                toastStore.showToast(
                  `${companySettings?.entityLabelSingular} Deleted`,
                  "success"
                );
                nav("/cards");
              })
              .catch((err) => {
                toastStore.showError(
                  `Failed Deleting ${companySettings?.entityLabelSingular}`,
                  err
                );
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      {deleting && (
        <CommonSpinner message="Deleting..." overlay={true}></CommonSpinner>
      )}
      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          <div id="entityForm">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="pt-2">
                  <div className="mb-2">
                    <div className="mb-1">
                      <label>User</label>
                    </div>
                    <div>
                      {!entity.data?.id && (
                        <>
                          <AsyncTypeahead
                            id="typeahead-search-user"
                            labelKey="name"
                            renderMenuItemChildren={(option, props, index) => (
                              <TyeaheadItem {...itemUser[index]}></TyeaheadItem>
                            )}
                            onSearch={handleSearchUsers}
                            onChange={(data) => {
                              if (data.length > 0) {
                                const item = data[0] as any;
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    userId: item ? item.id : undefined,
                                    userFullName: item ? item.name : undefined,
                                  };
                                });
                                (refUser.current as any)?.clear();
                              }
                            }}
                            searchText={"Searching..."}
                            isLoading={isLoadingUsers}
                            options={itemUser}
                            placeholder="Search User"
                            minLength={1}
                            delay={500}
                            useCache={false}
                            filterBy={() => true}
                            ref={refUser}
                            autoFocus={true}
                          />
                        </>
                      )}
                      {model.userId ? (
                        <div
                          className={`alert alert-sm alert-${
                            entity.data?.id ? "secondary" : "primary"
                          } mt-2 p-2 px-3`}
                        >
                          {model.userFullName}
                        </div>
                      ) : (
                        <div
                          className={`alert alert-sm alert-${
                            entity.data?.id ? "secondary" : "primary"
                          } mt-2 p-2 px-3`}
                        >
                          <i>No User</i>
                        </div>
                      )}
                    </div>
                  </div>
                  {entityTemplate.data?.fields
                    ?.sort(commonService.sortByNumericProperty("sequence"))
                    ?.map((field) => (
                      <div className="mb-2" key={field.id}>
                        <div className="mb-1">
                          <label
                            className={
                              field.isFieldRequired ? "required-label" : ""
                            }
                          >
                            {field.name}
                          </label>
                        </div>
                        <div className="input-with-help-icon">
                          {!commonService.isNullOrEmpty(
                            field.description || ""
                          ) && (
                            <i
                              className="fa fa-info-circle text-primary pointer"
                              title={field.description}
                            ></i>
                          )}
                          {field.fieldType === SysModels.WF1FieldType.Date && (
                            <>
                              <div id={`field${field.id}`}>
                                <DateTimePicker
                                  dateOnly={true}
                                  data={getFiedlValues(field.id)}
                                  onChange={(data) => {
                                    setFieldValue(field.id, data || "");
                                  }}
                                  disabled={props.viewOnly}
                                />
                              </div>
                              {field.isFieldRequired && (
                                <HiddenInputRequiredRef
                                  value={getFiedlValues(field.id)}
                                  placeholder={field.name || ""}
                                  onFocus={() => {
                                    document
                                      .getElementById(`field${field.id}`)
                                      ?.querySelector("input")
                                      ?.focus();
                                  }}
                                />
                              )}
                            </>
                          )}
                          {field.fieldType === SysModels.WF1FieldType.Text && (
                            <input
                              type="text"
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly}
                            />
                          )}
                          {field.fieldType ===
                            SysModels.WF1FieldType.TextArea && (
                            <textarea
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              rows={3}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly}
                            />
                          )}
                          {field.fieldType ===
                            SysModels.WF1FieldType.Number && (
                            <input
                              type="number"
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="pt-2">
                  {!props.viewOnly && (
                    <>
                      {entity.data?.id && (
                        <button
                          className={`btn btn-sm btn-danger ${
                            props.viewOnly ? "" : "me-2"
                          }`}
                          type="button"
                          onClick={(e) => {
                            setShowDel(true);
                          }}
                        >
                          Delete
                        </button>
                      )}
                      <button
                        className="btn btn-sm btn-primary float-right"
                        type="button"
                        onClick={(e) => {
                          save();
                        }}
                        disabled={saving}
                      >
                        {saving ? "Saving..." : "Submit"}
                      </button>
                    </>
                  )}
                  <button
                    className={`btn btn-sm btn-secondary float-right ${
                      props.viewOnly ? "" : "me-2"
                    }`}
                    type="button"
                    onClick={(e) => {
                      if (props.viewOnly) {
                        nav("/view-reminders");
                      } else {
                        nav("/cards");
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Tab>
        {!!entity.data?.id && (
          <Tab eventKey="Notifications" title="Notifications">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Notifications") && (
                <NotificationsTab
                  list={entity.data?.notificationOutputDetailDtos || []}
                ></NotificationsTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && (
          <Tab eventKey="Logs" title="Logs">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Logs") && (
                <div>
                  <ActivityLogs
                    type={SysModels.LogObjectTypeEnum.Entity}
                    stringId={id}
                    forTab={true}
                  ></ActivityLogs>
                </div>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Entity;
