import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PickCategoryDialog from "./PickCategoryDialog";
import Pagination, { usePaging } from "../components/Pagination";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useActiveRole, useLastPageFilters } from "../stores/SystemStore";
import SysModels from "../models";
import CommonSpinner from "../components/CommonSpinner";
import { Dropdown } from "react-bootstrap";

function CategoryReminders(props: any) {
  const nav = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [filter, setFilter] = useState(SysModels.ReminderListFilterEnum.All);
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const filterList = useFetchHelper(
    async () =>
      SysServices.http.genericEnumLookup.get("ReminderListFilterEnum"),
    "Filters"
  );

  const list = useFetchHelper(
    async () =>
      SysServices.http.templateCategory.listWithReminders(
        paging.page,
        paging.pageSize,
        {
          search: search.used,
          reminderListFilterEnum: filter,
        }
      ),
    "Categories"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
      others: {
        filter: SysModels.ReminderListFilterEnum.All,
      },
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
        filters.others && setFilter(filters.others["filter"] || 0);
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
          others: {
            filter: filter,
          },
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready, filter]);

  useEffect(() => {
    filterList.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeRole = useActiveRole();

  return (
    <div>
      {showDialog && (
        <PickCategoryDialog
          route="/category-reminders"
          onClose={() => setShowDialog(false)}
        />
      )}
      <div className="mb-3">
        <h4>Category Reminder</h4>
      </div>

      <div className="flex flex-wrap gap-10 mb-3">
        <div className="flex-0" style={{ maxWidth: "100%" }}>
          <div className="input-group search-box">
            <input
              autoFocus={true}
              className="form-control"
              type="text"
              placeholder="Search"
              value={search.typed}
              onChange={(e) => {
                setSearch((data) => {
                  return {
                    ...data,
                    typed: e.target.value,
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }
              }}
            ></input>
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-search"></i>
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    return { typed: "", used: "" };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-secondary"
              disabled={list.status === FetchStatus.InProgress}
              className="no-wrap"
            >
              <span className="mx-2">
                {filterList?.data?.find((item) => item.value === filter)
                  ?.label || "Loading..."}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              {filterList.data?.map((item) => (
                <Dropdown.Item
                  key={item.value}
                  active={filter === item.value}
                  onClick={() => {
                    setFilter(item.value || 0);
                    pageChange(1, paging.pageSize);
                  }}
                >
                  {item.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="flex-1"></div>
      </div>

      {list.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {list.status !== FetchStatus.InProgress && (
        <>
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th className="w-50">Name</th>
                <th className="w-50">Description</th>
                <th className="w-20 no-wrap">Has Notifications?</th>
              </tr>
            </thead>
            <tbody>
              {!list.data?.totalRecords && (
                <tr>
                  <td colSpan={4}>No Record(s) Found</td>
                </tr>
              )}
              {list?.data?.templateCategories?.map((category) => (
                <tr
                  key={category.id}
                  className="pointer"
                  onClick={(e) => {
                    nav(`/category-reminders/${category.id}`);
                  }}
                >
                  <td>{category.name}</td>
                  <td>{category.description}</td>
                  <td className="text-center">
                    {category.reminderSetting?.reminderSettingDetailDtos
                      ?.length ? (
                      <span className="chip-green">Yes</span>
                    ) : (
                      <span className="chip-red">No</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="hide-on-print">
            <Pagination
              length={list.data?.totalRecords || 0}
              page={paging.page}
              pageSize={paging.pageSize}
              pageChange={pageChange}
              showingOfWhatLabel="Categories"
              sizes={[10, 15, 25, 50, 100]}
            ></Pagination>
          </div>
        </>
      )}
    </div>
  );
}

export default CategoryReminders;
