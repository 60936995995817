import SysModels from "../../models";
import fetcher from "../Fetcher";

class GenericEnumLookupService {
  get = async (enumName: string) => {
    const url = `/GenericEnumLookup/${enumName}`;
    return fetcher.get<SysModels.LookupIntDto[]>(url);
  };

  getRolesEnums = async () => {
    const url = `/GenericEnumLookup/RolesEnum`;
    const data = fetcher.getCachedData(url);
    if (data) {
      return await new Promise<SysModels.LookupIntDto[]>((resolve) => {
        setTimeout(() => resolve(data), 100);
      });
    }

    return fetcher
      .fetchUseExisting<SysModels.LookupIntDto[]>(url)
      .then((data) => {
        fetcher.setCachedData(url, data);
        return data;
      });
  };
}

const genericEnumLookup = new GenericEnumLookupService();
export default genericEnumLookup;
