import SysModels from "../../models";
import fetcher from "../Fetcher";

class TemplateCategoryService {
  get = async (id: string) => {
    const url = `/TemplateCategory/${id}`;
    return fetcher.get<SysModels.TemplateCategoryOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.TemplateCategorySearchInputDto
  ) => {
    const url = `/TemplateCategory/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.TemplateCategoryGridOutputDto>(url, model);
  };

  listWithReminders = async (
    page: number,
    pageSize: number,
    model: SysModels.TemplateCategoryReminderSearchInputDto
  ) => {
    const url = `/TemplateCategory/ListWithReminders/${page}/${pageSize}`;
    return fetcher.post<SysModels.TemplateCategoryGridWithReminderOutputDto>(
      url,
      model
    );
  };

  typeAhead = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/TemplateCategory/TypeAhead`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  create = async (model: SysModels.TemplateCategoryInputDto) => {
    const url = `/TemplateCategory`;
    return fetcher.post<SysModels.TemplateCategoryOutputDto>(url, model);
  };

  update = async (id: string, model: SysModels.TemplateCategoryInputDto) => {
    const url = `/TemplateCategory/${id}`;
    return fetcher.put<SysModels.TemplateCategoryOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/TemplateCategory/${id}`;
    return fetcher.delete<any>(url);
  };
}

const templateCategory = new TemplateCategoryService();
export default templateCategory;
