import React from "react";
import ActivityLogs from "../components/ActivityLogs";

function AllActivityLogs(props: any) {
  return (
    <div>
      <div className="mb-3">
        <h4>Activity Logs</h4>
      </div>
      <ActivityLogs></ActivityLogs>
    </div>
  );
}

export default AllActivityLogs;
