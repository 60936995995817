import SysModels from "../../models";
import fetcher from "../Fetcher";

class ImportExportDefinitionService {
  get = async (id: string, entityTemplateId: string) => {
    const url = `/ImportExportDefinition/${id}/${entityTemplateId}`;
    return fetcher.get<SysModels.ImportExportOutputDto>(url);
  };

  create = async (data: SysModels.ImportExportInputCreateDto) => {
    const url = `/ImportExportDefinition`;
    return fetcher.post<SysModels.ImportExportOutputDto>(url, data);
  };

  update = async (
    id: string,
    entityTemplateId: string,
    data: SysModels.ImportExportInputUpdateDto
  ) => {
    const url = `/ImportExportDefinition/${id}/${entityTemplateId}`;
    return fetcher.put<SysModels.ImportExportOutputDto>(url, data);
  };

  delete = async (id: string, entityTemplateId: string) => {
    const url = `/ImportExportDefinition/${id}/${entityTemplateId}`;
    return fetcher.delete<any>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.ImportExportSearchInputDto
  ) => {
    const url = `/ImportExportDefinition/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.ImportExportGridDto>(url, model);
  };
}

const importExportDefinition = new ImportExportDefinitionService();
export default importExportDefinition;
