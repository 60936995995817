import React, { useEffect, useState } from "react";
import FormModal from "../components/FormModal";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { useNavigate } from "react-router-dom";

function PickTemplateDialog(props: { onClose: () => void }) {
  const nav = useNavigate();
  const [temp, setTemp] = useState<{ id: string; name: string }>();
  const [itemTemplates, setItemTemplates] = useState([] as any[]);
  const [isLoadingTemps, setIsLoadingTemps] = useState(false);
  const refCateg = React.createRef<any>();
  const handleSearchTemps = async (query: string) => {
    let focus = false;
    if ((query || "").trim() === "*") {
      focus = true;
      (refCateg.current as any)?.clear();
    } else if ((query || "").trim().length < 3) {
      setItemTemplates([]);
      setIsLoadingTemps(false);
      return;
    }
    setIsLoadingTemps(true);
    await SysServices.http.entityTemplate
      .typeAheadForEntity({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemTemplates(options);
        setIsLoadingTemps(false);
        if (focus) {
          commonService.focusInput("typeaheadSearchInput");
        }
      })
      .catch((err) => {
        setItemTemplates([]);
        setIsLoadingTemps(false);
      });
  };

  useEffect(() => {
    commonService.focusInput("typeaheadSearchInput");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      isOpen={true}
      title="Pick Template"
      size="md"
      close={() => {
        setTemp(undefined);
        props.onClose();
      }}
      submit={() => {
        if (temp) {
          nav(`/cards/new/${temp.id}`);
          props.onClose();
        }
      }}
      disableSubmit={!temp}
      submitButtonLabel="Continue"
    >
      <div className="pb-2">
        <label className="mb-2">Please pick an Template:</label>
        <div className="flex flex-row">
          <div className="flex-1">
            <AsyncTypeahead
              id="typeahead-search-template"
              labelKey="name"
              renderMenuItemChildren={(option, props, index) => (
                <TyeaheadItem {...itemTemplates[index]}></TyeaheadItem>
              )}
              onSearch={handleSearchTemps}
              onChange={(data) => {
                if (data.length > 0) {
                  setTemp({
                    id: (data[0] as any).id,
                    name: (data[0] as any).name,
                  });
                  (refCateg.current as any)?.clear();
                }
              }}
              searchText={"Searching..."}
              isLoading={isLoadingTemps}
              options={itemTemplates}
              placeholder="Search and Pick Template"
              minLength={0}
              delay={500}
              useCache={false}
              filterBy={() => true}
              ref={refCateg}
              autoFocus={true}
            />
            <HiddenInputRequiredRef
              id="typeaheadSearchInput"
              value={""}
              placeholder="Search"
              onFocus={() => {
                refCateg.current?.focus();
              }}
            />
          </div>
          <div>
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={(e) => {
                //setAddField(true);
                handleSearchTemps("*");
              }}
              title="Show All"
            >
              <i className="fa fa-list-ul"></i>
            </button>
          </div>
        </div>
        {temp && (
          <div className="alert alert-sm alert-primary mt-2 p-2 px-3 mb-0">
            <strong>{temp.name}</strong>
          </div>
        )}
      </div>
    </FormModal>
  );
}

export default PickTemplateDialog;
