import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import { useLastPageFilters } from "../stores/SystemStore";

function Categories(props: any) {
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(
    async () =>
      SysServices.http.templateCategory.list(paging.page, paging.pageSize, {
        search: search.used,
      }),
    "Categories"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready]);

  return (
    <div>
      <div className="mb-3">
        <h4>Categories</h4>
      </div>
      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {pageFilters.ready && (
        <>
          <div className="flex flex-wrap gap-10 mb-3">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  nav("/categories/new");
                }}
              >
                Add Category
              </button>
            </div>
            <div className="flex-1"></div>
          </div>

          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {!list.data?.totalRecords && (
                    <tr>
                      <td colSpan={2}>No Record(s) Found</td>
                    </tr>
                  )}
                  {list?.data?.templateCategories?.map((item) => (
                    <tr
                      key={item.id}
                      className="pointer"
                      onClick={(e) => {
                        nav(`/categories/${item.id}`);
                      }}
                    >
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="Categories"
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Categories;
